import Layout from "@/components/Layout";
import { useFetchJson } from "@/lib/fetchJson";
import React from "react";
import { PlayerInfo } from "@/lib/types";
import Loader from "@/components/Loader";
import BrowseDisplay from "@/components/Browse/BrowseDisplay";
import Head from "next/head";


const Browse: React.FC = () => {
  const { data: playersInfos, error, loading } = useFetchJson<PlayerInfo[]>(['/api/playersList'], []);

  if (loading) {
    return <Loader />;
  }

  if (playersInfos === null) {
    return (<div>Unable to fetch players infos</div>);
  }

  return <BrowseDisplay data={playersInfos} />;
};

const BrowsePage: React.FC = () => {
  return (
    <Layout>
      <Head>
        <title>Player X-Ray - Browse</title>
      </Head>
      <Browse />
    </Layout>
  );
};

export default BrowsePage;
